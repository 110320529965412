<template>
  <header
    class="fixed py-[13px] inset-x-0 z-[9999] hidden w-full lg:flex transition-all duration-150 h-[88px]"
    :class="navClasses"
  >
    <div
      class="container flex items-center justify-between gap-4 transition-all xl:px-28"
      :class="[
        state.scrollPosition >= 80 || state.isStorePage ? '' : ' is-not-scroll',
      ]"
    >
      <nav class="flex items-center justify-between">
        <!-- Logo -->
        <nuxt-link
          :to="$i18nPath('/')"
          aria-label="Benda Logo"
          class="transition-all py-[5px] ltr:pr-[69px] ltr:pl-[2px] rtl:pr-[2px] rtl:pl-[69px]"
          :prefetch="false"
        >
          <Logo
            :color="
              state.scrollPosition >= 80 ||
              (state.isStorePage && !state.isProductPage)
                ? '#000000'
                : 'white'
            "
            class="transition-all xl:h-[51.129px] h-auto w-[99px] xl:w-[117.237px]"
          />
        </nuxt-link>

        <!-- Models -->
        <FlyoutMenu
          v-if="products.length > 0"
          is-product
          :link-title="$t('common.models')"
        />

        <!-- <FlyoutMenu v-if="categories.length > 0">
          <a
            class="uppercase cursor-pointer nav-link hover-effect"
            :class="[
              state.isProductPage ? 'nav-product' : '',
              dynamicLinkClass,
              newTextClass,
            ]"
            v-text="$t('common.accessories')"
          />
        </FlyoutMenu> -->

        <span
          v-if="products.length > 0"
          class="mx-6 h-6 w-[3px] bg-primary inline-block"
        ></span>

        <div class="flex items-center space-s-7">
          <!-- Stores -->
          <!-- v-if="store.enableShop" -->
          <nuxt-link
            :to="$i18nPath(store.pageLinks['stores'])"
            class="nav-link hover-effect"
            :class="[dynamicLinkClass, newTextClass]"
            :prefetch="false"
          >
            {{ $t("common.stores") }}
          </nuxt-link>

          <!-- News -->
          <nuxt-link
            v-if="currentSite && currentSite.news"
            :to="$i18nPath(store.pageLinks['news'])"
            class="nav-link hover-effect"
            :class="[dynamicLinkClass, newTextClass]"
            :prefetch="false"
          >
            {{ $t("common.news") }}
          </nuxt-link>
          <!-- About us -->
          <nuxt-link
            :to="$i18nPath(store.pageLinks['about-us'])"
            class="nav-link hover-effect"
            :class="[dynamicLinkClass, newTextClass]"
            :prefetch="false"
          >
            {{ $t("common.about_us") }}
          </nuxt-link>
          <!-- Shop online -->
          <nuxt-link
            v-if="store.enableShop"
            :to="$i18nPath('/search')"
            class="nav-link hover-effect"
            :class="[dynamicLinkClass, newTextClass]"
            :prefetch="false"
          >
            {{ $t("common.shop_online") }}
          </nuxt-link>

          <nuxt-link
            v-if="
              currentSiteSettings &&
              currentSiteSettings.become_a_partner &&
              store.country != 'int'
            "
            :to="$i18nPath(store.pageLinks['become-a-dealer'])"
            class="nav-link hover-effect"
            :class="[dynamicLinkClass, newTextClass]"
            :prefetch="false"
          >
            {{ $t("common.become_a_dealer") }}
          </nuxt-link>
          <nuxt-link
            v-if="
              currentSiteSettings &&
              currentSiteSettings.become_a_partner &&
              store.country === 'int'
            "
            :to="$i18nPath(store.pageLinks['become-a-distributor'])"
            class="nav-link hover-effect"
            :class="[dynamicLinkClass, newTextClass]"
            :prefetch="false"
          >
            {{ $t("common.become_a_distributor") }}
          </nuxt-link>
          <!-- Contact us -->
          <!-- <nuxt-link
            :to="$i18nPath(store.pageLinks['contact-us'])"
            class="font-medium nav-link hover-effect font-zuume-semibold"
            :class="[scrollPosition >= 80 ? 'text-secondary-dark': 'text-white']"
            :prefetch="false"
            v-text="$t('common.contact_us')"
          /> -->
          <!-- Locale selector -->
        </div>
      </nav>
      <nav>
        <LocaleSelector
          :color="color"
          :name="state.isProductPage ? 'headerproduct' : 'header'"
        />
        <!-- User selector -->
        <UserDropdown v-if="store.enableShop" :color="color" />
      </nav>
    </div>
  </header>
</template>
<script setup>
import { useMainStore } from "@/store/index";

const nuxtApp = useNuxtApp();
const store = useMainStore();
const route = useRoute();
const router = useRouter();

const $i18nPath = nuxtApp.$i18nPath;
const color = ref("white");
const state = reactive({
  isStorePage: false,
  isProductPage: false,
  isAboutUsPage: false,
  scrollPosition: 0,
});

if (store && store.pageLinks) {
  const srPageName = nuxtApp.$getKeyByValue(
    store.pageLinks,
    router.currentRoute.value.params.page
  );

  state.isStorePage = !!(srPageName == "stores");
  state.isAboutUsPage = !!(srPageName == "about-us");
}

const navClasses = computed(() => {
  const classes = [];
  /* } else if (removeLastDash($i18nPath($route.path)) === removeLastDash($i18nPath('/'))) {
    classes.push('bg-opacity-20')
  } */
  if (route.path.startsWith("/products/")) {
    classes.push("md:mb-[-110px] bg-opacity-0");
  }
  if (state.scrollPosition >= 80 && state.isProductPage) {
    classes.push("bg-white scrollable-header ");
  } else if (state.scrollPosition >= 80 || state.isStorePage) {
    classes.push("bg-white scrollable-header scrollable-header-shadow");
  } else {
    classes.push("bg-gradient-to-b	from-[#00000054] to-[#0000000f]");
  }
  return classes;
});

const newTextClass = computed(() => {
  if (store.locale === "el" || store.locale === "ar") {
    return "xl:text-xs text-[11px] ";
  } else {
    return "xl:text-sm text-[11px] ";
  }
});
const dynamicLinkClass = computed(() => {
  return state.scrollPosition >= 80 || state.isStorePage
    ? "text-black  hover:text-black"
    : "text-white hover:text-white";
});

const products = computed(() => {
  return store.products;
});

const categories = store.categories;
const currentSite = store.currentSite;
const currentSiteSettings = store.currentSiteSettings;

// watch(
//   router.currentRoute,
//   (newValue, oldValue) => {
//     if (newValue.name == "country-products-slug") {
//       state.isProductPage = true;
//     } else {
//       state.isProductPage = false;
//     }
//     const pageName = nuxtApp.$getKeyByValue(
//       store.pageLinks,
//       router.currentRoute.value.params.page
//     );

//     if (pageName == "stores") {
//       state.isStorePage = true;
//     } else {
//       state.isStorePage = false;
//     }
//   },
//   { deep: true }
// );

onBeforeMount(() => {
  if (!process.browser) {
    return;
  }
  state.isProductPage = window.location.href.includes("/products/");
}),
  onMounted(() => {
    if (!process.browser) {
      return;
    }
    if (import.meta.client) {
      nuxtApp.hook("page:loading:end", () => {
        if (router.currentRoute.value.name == "country-products-slug") {
          state.isProductPage = true;
        } else {
          state.isProductPage = false;
        }
        const pageName = nuxtApp.$getKeyByValue(
          store.pageLinks,
          router.currentRoute.value.params.page
        );
        if (pageName == "stores") {
          state.isStorePage = true;
        } else {
          state.isStorePage = false;
        }
        if (pageName == "about-us") {
          state.isAboutUsPage = true;
        } else {
          state.isAboutUsPage = false;
        }
      });
    }
    window.addEventListener("scroll", updateScroll);
  });

onUnmounted(() => {
  window.removeEventListener("scroll", updateScroll);
});

function updateScroll() {
  state.scrollPosition = window.scrollY;
}

function changeColor(color) {
  color.value = color;
}
</script>

<style lang="postcss">
.nav-link {
  @apply block z-10 hover:text-primary leading-6 text-[11px] md:text-sm font-normal uppercase font-OPPO-sans-bold hover:opacity-100 hover:no-underline focus:no-underline  focus:outline-none relative;
  /* &.hover-effect::before {
    transform: scaleX(1);
  } */
  &.nav-product {
    @apply text-white hover:text-white;
    /* &.hover-effect::before {
      @apply bg-[#5A5A5A] !important;
    } */
  }
  &.nuxt-link-exact-active {
    @apply text-primary;
  }
  &.router-link-exact-active {
    @apply text-primary;
  }
}
.is-not-scroll {
  /* .nav-link.hover-effect::before {
    @apply bg-white;
  } */
}
.scrollable-header {
  @apply bg-white;
}
.scrollable-header-shadow {
  box-shadow: 0px 2px 2px 0px rgba(151, 151, 153, 0.12);
}
</style>
